<div class="navbar">
    <a class="nav-section" href="#contact"><span>03.</span>Contact</a>
    <a class="nav-section" href="#projects"><span>02.</span>Projects</a>
    <a class="nav-section" href="#experience"><span>01.</span>Experience</a>
    <a class="nav-section" href="#about"><span>00.</span>About</a>
  </div>
  <div class="greeting-container">
    <div class="greeting">Hey there! My name is</div>
    <h1 class="name">Elliot Jimeno.</h1>
    <div class="greeting">I'm a Software Developer.</div>
  </div>
  <!-- About Section -->
  <div class="section">
    <h3 id="about" class="heading">About Me</h3>
    <p class="about">
      Hello! My name is Elliot Jimeno. I am a result-oriented project team
      leader with experience covering software related project and product
      development. A passionate and optimistic individual, I am progress driven
      and eager to learn new things to help meet business goals. I am comfortable 
      with ambiguity and thrive in fast-paced environments. I graduated with a 
      Masters degree in Computer Science at 
      <a target="_blank" href="https://graduateschool.vt.edu/">Virginia Tech</a>
      with a focus in Software Development, and am currently working as a Software
      Engineer at 
      <a target="_blank" href="https://www.bti360.com/">BTI360</a>.
    </p>
  </div>
  <!-- Experience Section -->
  <div class="section">
    <h3 id="experience" class="heading">Experience</h3>
    <div class="date">June 2024 &mdash; Present</div>
    <h3>Software Engineer <a target="_blank" href="https://www.bti360.com/">&#64; BTI360</a></h3>
    <p>
      Developing & maintaining critical applications supporting national 
      defense initiatives, by enhancing OSINT capabilities essential for strategic
      decision making.
    </p>
    <div class="skills">
      <div class="skill">Agile</div>
      <div class="skill">React</div>
      <div class="skill">Splunk</div>
      <div class="skill">Git</div>
    </div>
    <!-- <p>
      Taught fundamentals of decision structures, Booleans, loops, iterators, 
      exceptions, errors, arrays, & array lists. Fostered a welcoming and 
      productive classroom environment by keeping students mentally engaged 
      and physically motivated
    </p>
    <div class="skills">
      <div class="skill">Leadership</div>
      <div class="skill">Communication</div>
      <div class="skill">Organization</div>
    </div> -->
    <div class="date">May &mdash; August 2022</div>
    <h3>Software Developer Intern <a target="_blank" href="https://www.ventera.com/ventera-internship-program-is-back/">&#64; Ventera</a></h3>
    <p>
      Used agile methodologies to plan, design, develop, and present a 
      complex intern web application from start to finish. Developed the 
      majority of the front end while gaining significant hands-on 
      experience with Node, MySQL, and Angular
    </p>
    <div class="skills">
      <div class="skill">Angular</div>
      <div class="skill">Node</div>
      <div class="skill">Express</div>
      <div class="skill">MySQL</div>
    </div>
    <div class="date">May &mdash; August 2022</div>
    <h3>Software Developer Intern <a target="_blank" href="https://www.sequoiainc.com/">&#64; Sequoia</a></h3>
    <p>
      Contributed to the development of a full life cycle web application 
      designed to improve contract management. Developed a secure landing 
      and login page with Angular that manages and authenticates user 
      account information
    </p>
    <div class="skills">
      <div class="skill">Angular</div>
      <div class="skill">HTML/CSS</div>
      <div class="skill">Typescript</div>
      <div class="skill">Git</div>
    </div>
    <div class="date">December 2021 &mdash; January 2022</div>
    <h3>Software Developer Intern <a target="_blank" href="https://www.tred-solutions.com/">&#64; TrEd Solutions</a></h3>
    <p>
      Conducted Research on digital marketing industry and demand side 
      platform (DSP) APIs. Contributed to AdTech campaign by modifying 
      existing shell script files to use with alternate demand side platforms.
      Applied CRUD API calls to implement an updated program for company's 
      software related needs
    </p>
    <div class="skills">
      <div class="skill">API</div>
      <div class="skill">Linux</div>
      <div class="skill">Shell Script</div>
    </div>
    <!-- <div class="date">June 2021 &mdash; January 2022</div>
    <h3>Java Programming Instructor <a target="_blank" href="https://aigolearning.org/">@ AIGO Learning</a></h3>
    <p>
      Taught fundamentals of decision structures, Booleans, loops, iterators, 
      exceptions, errors, arrays, & array lists. Fostered a welcoming and 
      productive classroom environment by keeping students mentally engaged 
      and physically motivated
    </p>
    <div class="skills">
      <div class="skill">Leadership</div>
      <div class="skill">Communication</div>
      <div class="skill">Organization</div>
    </div> -->
  </div>
  <!-- View Full Resume -->
  <div class="resume">
    <a class="view" href="../assets/resume_112424.pdf">View Full Resume</a>
    <mat-icon>arrow_right_alt</mat-icon>
  </div>
  <!-- Projects Section -->
  <div id="projects" class="section">
    <h3 class="heading">Projects</h3>
    <h3>Social Media Management</h3>
    <a target="_blank" href="https://main.dh64c05ljyzbd.amplifyapp.com">FinnaPost</a>
    <p>
      Software as a service (SaaS) web app and online tool designed to help
      create, plan, publish, and analyze your social media content. Built with
      Angular and integrated with multiple external social media APIs. 
    </p>
    <p>
      Conducted research while applying problem solving, entrepreneurial, and web 
      development skills to create my very own multiframework SaaS product and 
      passion project from the ground up.
    </p>
    <div class="skills">
      <div class="skill">Angular</div>
      <div class="skill">Full Stack</div>
      <div class="skill">AWS</div>
      <div class="skill">API</div>
      <div class="skill">CI/CD</div>
    </div>
    <a href="https://main.dh64c05ljyzbd.amplifyapp.com">
      <img class="project-visual" src="../assets/finnapost.png" alt="FinnaPost Image">
    </a>
    <h3>Decentralized Polling Application</h3>
    <a target="_blank" href="../../assets/smartpoll_demo.mp4">
      Smart Poll
    </a>
    <p>
      In an effort to provide a more secure and reliable means of voting, this
      project leverages blockchain technology in order to ensure a more reliable,
      secure, cost-effective, and time-saving approach to the election process.
    </p>
    <p>
      <!-- Built off of Ethereum using Hyperledger Besu, the system makes use of providing
      tokens through a smart contract and utilizes multiple levels of security such as
      one-time-passwords (OTP), Proof of Authority (PoA), and public/private key
      encryption.  -->
      Completed as a semester-long partner project for a graduate course at Virginia Tech, 
      I designed and implemented the entirety of the front-end, before eventually connecting
      to my partner's backend. This consisted of designing the components, integrating it with
      the Web3.js library, and communicating with the middleware. Read
      <a href="../../assets/smart-poll.pdf">Blockchain Powered Voting on the Ethereum Virtual Machine</a> for 
      more information. 
    </p>
    <div class="skills">
      <div class="skill">Blockchain</div>
      <div class="skill">Web3.js</div>
      <div class="skill">MetaMask</div>
    </div>
    <a href="../../assets/smartpoll_demo.mp4">
      <img class="project-visual" src="../assets/smartpoll.png" alt="Smart Poll Demo">
    </a>
    <h3>Running Blog</h3>
    <a target="_blank" href="https://thegrandmile.com/">The Grand Mile</a>
    <p>
      Dynamic, interactive, and personal platform where I share my insights, expertise,
      and passion for running. Inspired by a goal to run 1,000 miles in a year, the website
      highlights the running community that I have been able to foster alongside family and 
      friends. As of January 2024, the community currently has over 120 members!
    </p>
    <div class="skills">
      <div class="skill">React</div>
      <div class="skill">Mailchimp</div>
      <div class="skill">AWS Amplify</div>
    </div>
    <a href="https://thegrandmile.com">
      <img class="project-visual" src="../assets/thegrandmile.png" alt="The Grand Mile Image">
    </a>
    <h3>Student Chatroom</h3>
    <a target="_blank" href="https://master.dgvnkk43qj0db.amplifyapp.com/">Campus Yawns</a>
    <p>
      "Tongue-in-cheek" project built to connect college students who are bored during 
      lecture. In addition to providing access to amusement through a public chat room, 
      Campus Yawns aims to provide insightful server data describing the live website
      student demographic. 
    </p>
    <p>
      Users can view number of students online and from which colleges. Breakdowns of each 
      school by year and major are also visible. Future commits will include individual school
      and class chat rooms.
    </p>
    <div class="skills">
      <div class="skill">React</div>
      <div class="skill">AWS Lambda</div>
      <div class="skill">API Integration</div>
    </div>
    <a href="https://master.dgvnkk43qj0db.amplifyapp.com/">
      <img class="project-visual" src="../assets/campusyawns.png" alt="Campus Yawns Image">
    </a>
    <!-- <h3>Online Bookstore</h3>
    <a target="_blank" href="https://cs5244.cs.vt.edu:8443/ElliotBookstoreTransact/">Journey Pages</a>
    <p>
      eCommerce proof-of-concept web app that allows users to find and purchase
      books online. Built using the Vue framework as a web application development
      course project at Virginia Tech. Note that payments are purely for display 
      purposes and are not processed.
    </p>
    <div class="skills">
      <div class="skill">Vue</div>
      <div class="skill">HTML/CSS</div>
      <div class="skill">Javascript</div>
      <div class="skill">API</div>
      <div class="skill">SQL</div>
    </div>
    <a href="https://cs5244.cs.vt.edu:8443/ElliotBookstoreTransact/">
      <img class="project-visual" src="../assets/journeypages.png" alt="Journey Pages Image">
    </a> -->
  </div>
  <!-- <div class="resume" routerLink="projects">
    <a class="view">View All Projects</a>
    <mat-icon>arrow_right_alt</mat-icon>
  </div> -->
  <div class="section bottom">
    <h3 id="contact" class="heading">Contact</h3>
    <div class="contact-method">
      <img class="linkedin" src="../assets/linkedin.png" alt="">
      <a href="https://www.linkedin.com/in/elliot-jimeno/">Connect with me</a>
    </div>
    <div class="contact-method">
      <mat-icon class="contact-icon">phone</mat-icon>
      <p>(703) 727 0775</p>
    </div>
    <div class="contact-method">
      <mat-icon class="contact-icon">email</mat-icon>
      <a href="mailto: lemjimeno@verizon.net">lemjimeno&#64;verizon.net</a>
    </div>
  </div>
  